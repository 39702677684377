@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Kanit', sans-serif;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: rgb(var(--foreground-rgb));

  background-color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

p{
  font-family: 'Kanit', sans-serif;

}


.info-block-together{
  display: inline-grid;
  margin-top: 100px;
}

.info-block{
  width: 100%;
}

@media screen and (max-width: 1065px) {
  .info-block-together{
    display: flex;
    flex-direction: column;
  }
  .info-block{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
  }
}

.olx-button{
  background-color: #23E3DD;
  color: black;
  transition: 0.35s ease-in-out;
  margin-top: 100px;
  border: none;
}

.olx-button .inner {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
  transition: 0.35s ease-in-out;
}

.olx-button:hover {
  background-color: #E31E24;
  transition: 0.35s ease-in-out;

}

.olx-image{
  transition: 0.35s ease-in-out;
}

.olx-button:hover .olx-image{
  position: relative;
  transform: translate(250%);
  transition: 0.35s ease-in-out;
  display: flex;
  flex-direction: row;
}

.olx-arrow{
  display: none;
}

.olx-button:hover .olx-arrow{
  display: block;
  position: relative;
  left:10px;
  transition: 0.35s ease-in-out;
  top: 7px;
 
}

.olx-text{
  font-size: 20px;
  animation: textAppear 0.35s linear forwards;
}

.olx-button:hover .olx-text{
  animation: textDisappear 0.35s linear forwards;
}

@media screen and (max-width:500px) {
  .olx-text{
    font-size: 15px;
    line-height: 30px;
  }
}
@media screen and (max-width:370px) {
  .olx-text{
    font-size: 12px;
    line-height: 30px;
  }
}

@keyframes textDisappear {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes textAppear {
  0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}

.menu{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #1D1D1D;
  height: 125px;
  position: fixed;
  width: 100%;
  z-index: 10;
}
.link_menu{
  float: right;
  padding: 30px;
  padding-top: 52px;
  padding-bottom: 52px;
  position: relative;
  color: #ffffff;
  z-index: 10;
  transition: color 1s;
  cursor: pointer;
  font-size: 21px;
  background-color: #1D1D1D;
  transition: 0.3s ease-in-out;

}
.link_menu_right{
  float: right;
  padding: 30px;
  padding-top: 52px;
  padding-bottom: 52px;
  margin-right: 0px;
  position: relative;
  color: #ffffff;
  z-index: 10;
  transition: color 1s;
  cursor: pointer;
  font-size: 21px;
  background-color: #1D1D1D;
  transition: 0.3s ease-in-out;
}

.logo-container{
  position: fixed;
  background-color: #1D1D1D;
  width: 210px;
  height: 125px;
  transition: 0.3s ease-in-out;
}

.logo-container:hover{
  background-color: #E31E24;
  transition: 0.3s ease-in-out;
}

.logo_menu{
  position: fixed;
  width: 80px;
  height: 51px;
  top: 37px;
  left: 65px;
  z-index: 10;
  cursor: pointer;
  background-image: url(./assets/recolte_logo.png);
  transition: 0.3s ease-in-out;
}

.logo-container:hover .logo_menu{
  background-image: url(./assets/recolte_logo-black.png);
}

.info-block2{
  float: right;
  margin-right: 0px;
  position: relative;
  color: #ffffff;
  z-index: 10;
  transition: color 1s;
  cursor: pointer;
  font-size: 15x;
  background-color: #1D1D1D;
  height: 125px;
  margin-left: 60px;

}

.button-menu{
  position: relative;
  color: #ffffff;
  z-index: 10;
  transition: color 1s;
  cursor: pointer;
  font-size: 15x;
  background-color: #1D1D1D;
  width: 137px;
  height: 40px;
  top:50%;
  transform: translate(0,-50%);
  border-color: #FFFFFF;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  transition: 0.3s ease-in-out;
}

.button-menu:hover{
  border-color: #E31E24;
  color:#E31E24;
  transition: 0.3s ease-in-out;
}

.link_menu:hover{
  color: #1D1D1D;
  background-color: #E31E24;
  transition: 0.3s ease-in-out;
}
.link_menu_right:hover{
  color: #1D1D1D;
  background-color: #E31E24;
  transition: 0.3s ease-in-out;
}
.mobileNav{
  display: none;
}

.fa-time{
  display: block;
  position: relative;
  
  background-image: url("./assets/close.png");
  width: 23px;
  height: 17px;
  float: right;
  right: 27px;
  top: 27px;
  
}
.fa-bars{
  display: block;
  position: relative;
  background-image: url("./assets/close.png");
  width: 23px;
  height: 17px;
  float: right;
  right: 27px;
  top: 27px;
}

.mobile-menu{
  display: none;
  flex-direction: column;
  width: 100%;
  position: fixed;
  background-color: #E31E24;
  top: 70px;
  height: 100%;
  z-index: 1;
}



.image-mobile-menu{
  background-image: url(./assets/recolte_logo-mobile-background.png);
  width: 198px;
  height: 264px;
  z-index: 20;
  position: relative;
  left: 75%;
  transform: translate(-50%);
  bottom: 50px;
}

.cta-button {
  width: 350px; /* Slightly wider for emphasis */
  height: 65px; /* Increase height for better readability */
  font-size: 24px; /* Larger font for easier reading */
  font-weight: bold; /* Make text bold to catch attention */
  background-color: #E31E24; /* Brighter color for visibility */
  transition: 0.35s ease-in-out;
  color: #fff; /* White text for contrast */
  border: 2px solid #E31E24; /* Border to enhance contrast */
  position: absolute;
  right: 100px;
  top: 530px;
  margin-top: 30px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
  cursor: pointer; /* Pointer cursor for interactivity */
}


.cta-button:hover {
  background-color: #BF1A1E; /* Darken the red color on hover */
  border-color: #fff; /* White border for contrast on hover */
  color: #fff;
  transform: scale(1.05); /* Slightly enlarge the button */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); /* Increase shadow for more depth */
}

@media screen and (min-width:399px) {
  .image-mobile-menu{
    display: none;
  }
}


.main-section-background{
  background-image: url(./assets/welcome.jpg);
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35%;
  top: 50px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 580px) {
  .main-section-background{
   background-image: url(./assets/welcome-mobile2.png);
}
}


.main-h1{
  font-size: 92px;
  float: right;
  text-align: right;
  position: relative;
  margin-right: 100px;
  margin-left: 100px;
  line-height: 110px;
  color: white;
  margin-top: 200px;
}

.main-p{
  font-size: 30px;
  float: right;
  text-align: right;
  position: relative;
  margin-right: 100px;
  margin-left: 100px;
  color: white;
}

@media screen and (max-width: 888px) {
  .main-h1{
    font-size: 70px;
    line-height: 90px;

  }
  .main-p{
    font-size: 22px;
  }
  .cta-button{
    top:450px
  }
}

@media screen and (max-width: 728px) {
  .main-h1{
    font-size: 50px;
    line-height: 60px;
  }
  .cta-button{
    top:420px
  }
}

@media screen and (max-width: 584px) {
  .main-h1{
    font-size: 42px;
    text-align:left;
    margin-left: 30px;
    margin-right: 20px;
  }
  .main-p{
    text-align: left;
    font-size: 18px;
    margin-left: 30px;
    margin-right: 30px;
  }
  .cta-button {
    position: relative;
    width: fit-content;
    font-size: 20px;
    padding-inline: 10px;
    top: 0px;
    right: 0px;
    margin-left: 30px;
  }
}

#link-menu-mobile{
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  float: left;
  left: 60px;
  margin-bottom: 50px;
  top: 50px;
  font-size: 30px;
  color: #1D1D1D;
  font-size: 30px;
  font-weight: 700;
  background-color: transparent;
  padding: 0;
}


@media screen and (max-width: 1330px){
  .mobileNav{
      display: block;
      
  }
  .desktopNav{
      display: none;
  }

  .menu{
    height: 70px;
  }
  .logo-container{
    height: 70px;
  }
  .logo_menu{
    width: 45px;
    height: 29px;
    background-image: url(./assets/recolte_logo-mobile.png);
    left: 30px;
    top: 20px;
    background-size: cover;
  }
  

  .main-section-background{
    top: 70px;
  }

}

.text-home{
  display: flex;
  flex-direction: row;
  height: 205px;
  width: 100%;
}

#text-home2{
  margin-top:100px;
}


.text-home .inner-text{
  width: 50%;
  text-align: left;
  color: #1D1D1D;
  font-size: 20px;
  line-height: 35px;
  margin-left: 50px;
}

.text-home .inner-text2{
  width: 50%;
  text-align: left;
  color: #1D1D1D;
  font-size: 20px;
  margin-right: 20px;
  line-height: 35px;
}

.text-home .image1{
  width: 50%;
  position: relative;
  float: right;
  margin-right: 20px;
} 

.text-home .image2{
  width: 50%;
  position: relative;
  float: left;
  margin-left: 20px;
} 

.text-home .image1 .image{
  float: right;
}

@media screen and (max-width:1235px) {
  .text-home{
    flex-direction: column;
    height: 575px;
  }

  #text-home2{
    margin-top: 0px;
  }

  .text-home .inner-text{
    width: 85%;
    margin: auto;
    max-width: 752px;
    margin-bottom: 30px;
  }
  .text-home .inner-text2{
    width: 85%;
    margin: auto;
    max-width: 752px;
    position: relative;
    bottom: 310px;
    margin-top: 20px;
  }

  .text-home .image1{
    margin: auto;
    width: 90%;
    max-width: fit-content;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .text-home .image2{
    margin: auto;
    width: 90%;
    max-width: fit-content;
    top: 200px;
  }

  .text-home .image1 .image{
    width: 100%;
    float: unset;
  }
  .text-home .image2 .image{
    width: 100%;
    float: unset;
  }

}

@media screen and (max-width: 801px) {
  .text-home .inner-text2{
    bottom: 280px
  }
  .text-home .image2{
    top: 260px;
  }
}

@media screen and (max-width: 554px) {
  .text-home .inner-text2{
    bottom: 220px
  }
  .text-home .image2{
    top: 400px;
  }
}

@media screen and (max-width: 554px) {
  .text-home .inner-text2{
    bottom: 190px
  }
}

@media screen and (max-width: 470px) {
  .text-home .inner-text{
    font-size: 18px;
  }

  .text-home .inner-text2{
    bottom: 150px;
    font-size: 18px;
  }
  .text-home .image2{
    top: 440px;
  }
}
@media screen and (max-width: 345px) {
  .text-home .inner-text{
    font-size: 16px;
  }

  .text-home .inner-text2{
    bottom: 120px;
    font-size: 16px;
  }
  .text-home .image2{
    top: 500px;
  }
}

.desktop{
  display: flex;
}

.mobile{
  display: none;
}

@media screen and (max-width:1240px) {
    .mobile{
      display: flex;
    }
    .desktop{
      display: none;
    }
}

.faqUpper{
  background-color: #1D1D1D;
  transition: 0.3s ease-in-out;
  display: inline-grid;
  grid-template-columns: [text] auto [sign] 20px [empty] 30px;
  z-index: 20;
}

.faqUpper:hover{
  background-color: #E31E24;
  color: #1D1D1D;
  transition: 0.3s ease-in-out;
}

.faq-open{
  
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
  width: 20px;
  height: 20px;
  background-image: url(../src/assets/faqOpen.png);
}

.faqUpper:hover .faq-open{
  width: 20px;
  height: 20px;
  background-image: url(../src/assets/faqOpenBlack.png);
}

.faq-close{
  position: relative;
  top: 50%;
  transform: translate(0,-50%);
  width: 20px;
  height: 2px;
 background-image: url(../src/assets/faqClose.png) ;
}

#faqContent{
  transition: 0.3s ease-in-out;
}

.review-text{
  font-size:20px;
  color:#1D1D1D;
  font-weight: 300;
}

.lopka{
  width: 33px;
  height: 33px;
  background-image: url(./assets/search.png);
  position: relative;
  left: 20px;
  bottom:50px;
}

.blog-global-text{
  margin:auto;
}
.blog-global-input{
  padding:17px;
  padding-right:0px;
  padding-left: 100px;
}

@media screen and (max-width: 568px){
  .blog-global-text{
    margin-left:15px;
    margin-right:15px;
  }
  .blog-global-input{
    padding-left: 75px;
  }
}

.main-article-container{
  flex-direction: row;
  width:100%; 
}
.main-article-container .half1{
  width:50%;
}
.main-article-container .half2{
  width:50%;
}
.main-article-img{
  float:right;
  margin-right: 50px;
}
.main-article-h{
  font-size: 50px;
  max-width: 530px;
  margin-left:50px;
  line-height: 115%;
}
.main-article-p{
  color: #1D1D1D;
  font-size: 20px;
  max-width: 530px;
  margin-left:50px;
  margin-top:25px;
}

.article-container{
  display: grid;
  grid-template-columns: [first] 330px [second] 330px [third] 330px;  
  column-gap: 116px;
  row-gap: 80px;
}

@media screen and (max-width:1300px) {
  .main-article-container{
    flex-direction: column;
    width: 613px;
    margin:auto;
  }
  .main-article-container .half1{
    width:100%;
  }
  .main-article-container .half2{
    width:100%;
  }
  .main-article-h{
    margin-top:50px;
    margin-left:0px;
  }
  .main-article-p{
    margin-top:30px;
    margin-left:0px;
  }
  .article-container{
    grid-template-columns: [first] 330px [second] 330px; 
    width: fit-content;
    margin:auto;
  }
  .wpisy{
    width: 776px;
    margin:auto;
  }
}

@media screen and (max-width:830px) {
  .article-container{
    grid-template-columns: [first] 330px; 
  }
  .wpisy{
    width: 330px;
  }
}

@media screen and (max-width:640px) {
  .main-article-container{
    width: 100%;
  }
  .main-article-img{
    margin:auto;
    float: none;
    width: 100%;
  }
  .main-article-h{
    margin-left:20px;
    margin-right:20px;
  }
  .main-article-p{
    margin-left:20px;
    margin-right:20px;
  }
}

@media screen and (max-width:350px) {
  .article-container{
    grid-template-columns: [first] 100%; 
  }
  .wpisy{
    width: 100%;
  }
  .blog-article-h{
    margin-left:20px;
    margin-right:20px;
  }
  .blog-article-p{
    margin-left:20px;
    margin-right:20px;
  }
  .blog-article-p2{
    margin-left:20px;
    margin-right:20px;
  }
}

.article-font-h{
  font-size:50px;
  bottom: 150px;
  margin-left: 60px;
}
.article-font-mini-p{
  font-size: 15px;
  bottom: 150px;
  margin-left: 60px;
}

@media screen and (max-width:800px) {
  .article-font-h{
    font-size:35px;
    margin-left: 30px;
    bottom: 110px;
  }
  .article-font-mini-p{
    font-size: 15px;
    bottom: 110px;
    margin-left: 30px;
  }
}
@media screen and (max-width:534px) {
  .article-font-h{
    font-size:25px;
    margin-left: 20px;
    bottom: 170px;
  }
  .article-font-mini-p{
    font-size: 15px;
    bottom: 170px;
    margin-left: 20px;
  }
}
@media screen and (max-width:534px) {
  .article-font-h{
    font-size:30px;
    margin-left: 15px;
    bottom: 100px;
  }
  .article-font-mini-p{
    font-size: 16px;
    bottom: 100px;
    margin-left: 15px;
  }
}

.dots{
  width: 300px;
  height: 12px;
  margin: auto;
  position: relative;
  top:50px;
}

@media screen and (max-width:700px) {
  .dots{
    width: 175px;
    height: 7px;
    background-size: cover;
  }
  
}

#dot1{
  background-image: url(assets/dots1.png);
}
#dot2{
  background-image: url(assets/dots2.png);
}
#dot3{
  background-image: url(assets/dots3.png);
}
#dot4{
  background-image: url(assets/dots4.png);
}
#dot5{
  background-image: url(assets/dots5.png);
}
#dot6{
  background-image: url(assets/dots6.png);
}
#dot7{
  background-image: url(assets/dots7.png);
}
#dot8{
  background-image: url(assets/dots8.png);
}
#dot9{
  background-image: url(assets/dots9.png);
}

@media screen and (max-width:400px){
  .slider-title{
    margin-bottom:80px;
  }
}

.review-title{
  margin-bottom: 80px;
}

@media screen and (max-width:600px){
  .review-title{
    margin-bottom:80px;
  }
  .review-text{
    font-size: 16px;
  }
  .left-arrow{
    width: 40px;
    
  }
}

.form-container{
  display: flex;
  flex-direction: row;
}

.form-input{
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  font-size: 20px;
  border: none;
  padding-left:15px;
  margin: 10px;
}
.form-container-inner{
  width: 40%;
  margin-right:20px;
  margin-left:10px;
}
#form-input-message{
  width: 55%;
}

.form-input::placeholder{
  color:white;
}

@media screen and (max-width:600px) {
  .form-container{
    flex-direction: column;
  }
  .form-container-inner{
    width: 90%;
    margin:auto;
  }
  .form-input{
    font-size: 16px;
    margin:auto;
    margin-top: 10px;
    margin-bottom:10px;
  }
  #form-input-message{
    width: 90%;
    margin-top: 25px;
  }
}

.CheckboxRoot {
  background-color: white;
  color:black;
  width: 30px;
  height: 30px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 15px;
  box-shadow: 0 2px 10px var(--blackA7);
}
.CheckboxIndicator {
  color: var(--violet11);
}
.Label{
  width: fit-content;
}


.sendButton{
  width: 278px;
  height: 50px;
  font-size: 22px;
  background-color: #FFFFFF;
  transition: 0.35s ease-in-out;
  color:black;
}

.sendButton:hover{
  background-color: #E31E24;
  transition: 0.35s ease-in-out;
  cursor: pointer;
}

.bottom-form{
  display: grid;
  margin: auto;
  margin-top: 50px;
  grid-template-columns: [empty] auto [content1] 500px [button] 278px [empty2] 20px;
  padding-bottom: 100px;
}

@media screen and (max-width:820px) {
  .bottom-form{
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
  }
  .sendButton{
    width: 90%;
    margin: auto;
    margin-bottom: 30px;
  }
  .administrator-info{
    width: 90%;
    margin:auto;
    margin-bottom: 30px;
    margin-top: 0px;
  }
}

.errorInformation{
  color: red;
  margin-left: 10px;
}

.msquared{
  font-size: 18px;
}

.meter-slider{
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  background: #e31e25;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
;
}
.meter-slider:hover{
  opacity: 1; 
}

.meter-slider::-moz-range-thumb {
  width: 15px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #ffffff; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border:none;
}

.calculator-container-inner{
  display: grid;
  grid-template-columns: [el1] 300px [empty] auto [el2] auto;
}

@media screen and (max-width: 700px){
  .calculator-container{
    width: 80%;
  }
  .calculator-container-inner{
    display: flex;
    flex-direction: column;
  }
} 

.component-select{
  width: 300px;
}

@media screen and (max-width:380px) {
  .component-select{
    width: 100%;
  }
}

.cena-show{
  flex-direction: row;
}
#cena-container{
  display: none;
}
#cena-container2{
  display: none;
}
#thank-you-buttons{
  background-color: #1D1D1D;
  color: white;
}
 
.form-title{
  margin: auto
}

@media screen and (max-width: 530px){
  .form-title{
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
  }
}

#checking-error{
  position: relative;
  bottom: 80px;
  left: 330px;
}

@media screen and (max-width:1000px) {
  #checking-error{
    left:0px;
    bottom: 110px
  }
}


@keyframes ldio-t3jts6k398p {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-t3jts6k398p div {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 16px solid #e15b64;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-t3jts6k398p div {
  animation: ldio-t3jts6k398p 1s linear infinite;
  top: 50px;
  left: 50px
}
.loadingio-spinner-rolling-3d1gtw28m9y {
  position: relative;
  top:10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff00;
}
.ldio-t3jts6k398p {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.3);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-t3jts6k398p div { box-sizing: content-box; }
/* generated by https://loading.io/ */

.galeria-zdjecia {
  margin:6px;
}

.mobile-gallery{
  display: none;
}

@media screen and (max-width:1136px) {
  .desktop-gallery{
    display: none;
  }
  .mobile-gallery{
    display: block;
  }
}

.createdWebstry{
  color: white;
  width: 200px;
  margin: auto;
  z-index: 1;
  position: relative;
  top: 160px;
  font-size: 18px;
}

@media screen and (max-width:1200px) {
  .createdWebstry{
    position: relative;
    top: 500px;
    text-align: center;
  }
}

.pristilbud-background{
  width: 100%;
  min-height: 100vh;
  max-height: 300vh;
}

.transparent-background{
  top: 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 500%;
  height: 725px;
  transition: height 0.5s linear;
  
}

.inside-background{
  position: relative;
  display: flex;
  width: 100%;
  color: black;
  flex-direction: row;
  top: 150px;
}

.sliding-column{
  width: 20%;
  text-align: center;
}

.input{
  width: 700px;
  height: 55px;
  font-size: 22px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.216);
  border-style: solid;
  border-color: #FF4C00;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px,#fff 90deg,#0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: l19 1s infinite;
}
@keyframes l19 {
   0%   {background-position:0     0, 10px 10px, 20px 20px}
   33%  {background-position:10px  10px}
   66%  {background-position:0    20px,10px 10px,20px 0   }
   100% {background-position:0     0, 10px 10px, 20px 20px}
}