.footer-child,
.yt-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1d1d1d;
}
.yt-child {
  border-radius: 2px;
  background-color: #fff;
  transition: 0.3s ease-in-out;
  z-index: 1;
  cursor: pointer;
}

.yt-child:hover{
  background-color: red;
  transition: 0.3s ease-in-out;
}
.yt-icon {
  position: absolute;
  height: 52%;
  width: 12.26%;
  top: 24%;
  right: 82.58%;
  bottom: 24%;
  left: 5.16%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}
.obserwuj-nas-na {
  position: absolute;
  top: 24%;
  left: 40.58%;
  letter-spacing: -0.03em;
  z-index: 2;
}
.yt {
  height: 16.61%;
  width: 200px;
  top: 40.49%;
  right: 10.42%;
  bottom: 29.9%;
  left: 68.06%;
}
.fb,
.fb-icon,
.olx-icon,
.olx,
.yt {
  position: absolute;
}
.fb-icon {
  height: 60%;
  width: 9.68%;
  top: 20%;
  right: 83.87%;
  bottom: 20%;
  left: 18.45%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}




.olx-icon{
  height: 60%;
  width: 9.68%;
  top: 20%;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.olx-icon:hover + .yt-child{
  background-color: red;
  transition: 0.3s ease-in-out;
}

.fb {
  height: 16.61%;
  width: 200px;
  top: 17.23%;
  right: 10.42%;
  bottom: 53.16%;
  left: 68.06%;
}

.olx{
  height: 16.61%;
  width: 200px;
  top: 63.23%;
  right: 10.42%;
  bottom: 53.16%;
  left: 68.06%;
}

.fb-icon:hover ~ .yt-child {
  background-color: red;
  transition: 0.3s ease-in-out;
}

.obserwuj-nas-na:hover + .yt-child{
  background-color: red;
  transition: 0.3s ease-in-out;
}


.olx:hover + .yt-child{
  background-color: red;
  transition: 0.3s ease-in-out;
}

.recoltepurgmailcom {
  margin: 0;
  text-decoration: underline;
  position: relative;
  top: 80px;
}
.stare-kurowo {
  margin: 0;
}
.recoltepurgmailcom-botnica {
  position: relative;
  line-height: 30px;
  font-weight: 300;
}
.arrow-icon1,
.zobacz-na-mapie {
  position: absolute;
  top: 0;
  left: 0;
}
.arrow-icon1 {
  height: 78.25%;
  width: 5.91%;
  top: 12.31%;
  right: -0.52%;
  bottom: 9.44%;
  left: 94.61%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.mapa {
  position: relative;
  width: 172px;
  height: 13px;
}
.adres,
.div {
  position: absolute;
}
.adres {
  height: 41.86%;
  width: 190px;
  top: 29.24%;
  right: 56.32%;
  bottom: 28.9%;
  left: 29.79%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  color: #fff;
}
.div {
  top: 0;
  left: 24.44%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  font-weight: 300;
}
.call-icon {
  height: 100%;
  width: 9.63%;
  top: 9px;
  right: 90.37%;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.call-icon,
.div1,
.parent {
  position: absolute;
}
.parent {
  height: 27.66%;
  width: 99.26%;
  top: 72.34%;
  right: 0.74%;
  bottom: 0;
  left: 0;
}
.div1 {
  top: 0;
  left: 24.26%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  font-weight: 300;
}

.div2{
  position: absolute;
  top: 0;
  left: 24.26%;
  letter-spacing: -0.03em;
  font-weight: 300;
}

.call-icon1,
.group,
.group2,
.phones {
  position: absolute;
  top: 0;
  left: 0;
}
.call-icon1 {
  height: 100%;
  width: 9.56%;
  right: 90.44%;
  top: 9px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group,
.phones {
  height: 27.66%;
  width: 100%;
  right: 0;
  bottom: 72.34%;
}

.group2{
  height: 27.66%;
  width: 100%;
  right: 0;
  top: 70px;
}

.kontakt{
  position: absolute;
  bottom: 70px;
}
.phones {
  height: 15.61%;
  width: 140px;
  top: 38.24%;
  right: 42.36%;
  bottom: 55.15%;
  left: 48.19%;
  color: #fff;
}
.recolte-logo-icon {
  position: relative;
  height: 27.24%;
  width: 8.96%;
  top: 36.54%;
  right: 80.63%;
  bottom: 36.21%;
  left: 10.42%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.recolte-logo-container{
  position: relative;
  top: 100px;
}
.footer-item {
  position: absolute;
  height: 0.33%;
  width: 79.17%;
  top: 0;
  right: 10.42%;
  bottom: 99.67%;
  left: 10.42%;
  background-color: #fff;
}
.footer {
  height: 301px;
  text-align: left;
  font-size: 20px;
  color: #1d1d1d;
  font-family: Kanit;
  align-self: stretch;
}

@media screen and (max-width: 1200px){
  .footer{
    height: 700px;
  }

  .kontakt{
    bottom: 130px;
  }
  .recolte-logo-icon {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .recolte-logo-container{
    position: relative;
    margin: auto;
    width: 129px;
    top: 20px;
  }
  .adres {
    width: 190px;
    top: 0px;
    margin: auto;
    margin-top: 110px;
    right: 0px;
    bottom: 0px;
    left: 0px;

  }
  .phones {
    width: 140px;
    top: 0px;
    margin: auto;
    margin-top: 300px;
    right: 0px;
    bottom: 0px;
    left: 0px;

  }
  .call-icon1 {
    height: 13px;
    width: 13px;
  }
  .call-icon {
    height: 13px;
    width: 13px;
  }
  .parent {
    top: -20px;
  }
  .group{
    top: 10px
  }
  .group2{
    top: 40px;
  }
  .fb {
    height: 50px;
    width: 300px;
    font-size: 16px;
    line-height: 30px;
    top: 410px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .yt{
    height: 50px;
    width: 300px;
    font-size: 16px;
    line-height: 30px;
    top: 490px;
    left: 50%;
    transform: translate(-50%);
  }
  .olx{
    height: 50px;
    width: 300px;
    font-size: 16px;
    line-height: 30px;
    top: 570px;
    left: 50%;
    transform: translate(-50%);
  }
}
